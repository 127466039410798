import React, { useEffect, useState } from 'react'
import localStorage from 'localStorage'
import { Container, Input, CircularProgress, Button, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import SnackMessage from 'components/snackbar'
import Nav from 'components/nav'
import { post } from 'utils/request'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const postLogin = async (params) => {
    const res = await post('agents/sign_in', params)
    return res
  }
  const login = async (params) => {
    const res = await postLogin(params)
    if (res.id) {
      localStorage.setItem('USER_TYPE', 'agent')
      setOpen(true)
      setResMessage('登录成功')
      setLoading(false)
    }
  }
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true)
      login({ agent: { email, password } })
    }
  }

  useEffect(() => {
    localStorage.setItem('DEVICE_TYPE', 'WEB_AGENT')
    if (localStorage.getItem('Token')) {
      navigate('/')
    }
  })
  return (
    <Nav>
      <Container fixed maxWidth="xs">
        <Grid container>
          <Grid item xs className="pt-32">
            <Input placeholder="账号" type="text" onKeyPress={onKeyPress} fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
            <Input
              placeholder="密码"
              fullWidth
              type="password"
              onKeyPress={onKeyPress}
              value={password}
              className="mt-6"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              className="mt-8"
              color="primary"
              onClick={() => {
                setLoading(true)
                login({ agent: { email, password } })
              }}
            >
              {loading ? <CircularProgress size={24} /> : '登录'}
            </Button>
            <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
          </Grid>
        </Grid>
      </Container>
    </Nav>
  )
}
export default Login
